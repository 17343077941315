* {
    box-sizing: border-box;
}

body {
    font-family: Arial, Helvetica, sans-serif;
}
.main {
    padding-bottom: 50px;
}
.headerContainer {
    max-width: 900px;
    margin: 40px auto;
}
.container {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
}
.container .leftSide {
    flex: 1;
    margin-right: 40px;
}
.container .leftSide h1 {
    margin:0;
    font-size: 40px;
    color:#3A4B5C;

}
.container .leftSide p {
    font-size: 16px;
    margin-bottom: 40px;
    color:#6A7D88;

}
.container .leftSide input {
    width: 100%;
    border:0;
    border-bottom: 2px solid rgba(150,163,171, .5);
    padding:10px 2px;
    margin-bottom:20px;
    font-size: 14px;
    outline: none;

}
.container .leftSide input:disabled {
    opacity: .5;
}
.container .leftSide button {
    background-color:#227C9D;
    color:#FFF;
    font-size:15px;
    border:none;
    border-radius:10px;
    padding:15px 0;
    width: 100%;
    cursor:pointer;
    margin-top: 40px;
    text-transform: uppercase;
    transition: all ease .3s;

}
.container .leftSide button:hover{
    opacity: .8;
}
.container .leftSide button:disabled {
    opacity: .5;
}

.container .rightSide {
    flex: 1;
    margin-left:40px;
    display: flex;
}
.container .rightSide .grid {
    flex:1;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
}
.container .rightSide .rightBig {
    flex:1;
    display: flex;
}
.container .rightBig .rightArrow{
    position:absolute;
    width: 70px;
    height: 70px;
    background-color: #227C9D;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    margin-left: -35px;
    margin-top: 145px;
    transition: all ease .3s;
}
.container .rightBig .rightArrow:hover{
    opacity: .8;
}
@media (max-width: 770px) {
    header{
        padding: 0 20px;
    }
    .container {
        padding: 0 20px;
        flex-direction: column;
    }
    .container .leftSide {
        margin-right: 0;
    }
    .container .rightSide {
        margin-left: 0;
        margin-top:50px;
    }
    .container .rightSide .rightArrow {
        margin-left: 0;
        margin-top:0;
        border-radius: 10px;
    }
}
@media (max-width: 430px) {
    .container .rightSide .grid {
        grid-template-columns: 1fr;
    }
}